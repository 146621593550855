import React from 'react';
import {
  ChakraProvider,
  Text,
  Image,
  Container,
  theme,
  Heading,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player'

import backgroundSrc from './background.mov'
import './App.css';
import logo from './logo.svg'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container size='xs'><Image src={logo} width='100px' style={{margin:'11px auto'}}/></Container>
      
      <Heading as='h1' size='3xl' style={{color: 'white'}}>No Longer Inc.</Heading>
      <Container size='md'>
        <Heading as='h2' size='1xl' style={{color: 'white', marginTop: '11px'}}>
          A corporation no longer as you know it. A focused group of people whose goal is to uplift humanity. We believe profit should be a side-effect of business; not the main focus. Our products and solutions aim to first and foremost bring utility and prosperity to the universe.
        </Heading>
        <Heading style={{ marginTop: '11px',display:'inline-flex'}} as='h3' size='lg'>
          <Text as='a' href='mailto: hello@nolonger.inc' bgGradient={[
            'linear(to-t, white, white)',
            'linear(to-b, #5087d8, #5087d8)',
          ]}
          alt='Email us'
          title='Email hello@nolonger.inc'
          _hover={{
            transition: '0.25s all ease-in-out',
            color: 'white'
        }}
          bgClip='text' style={{ cursor: 'pointer'}}>Reach out to us</Text>
        </Heading>
        <Heading style={{ marginTop: '55px',cursor: 'pointer'}} as='h3'>
          <Text as ='a' href='#' bgGradient='linear(to-l, #7928CA, #FF0080)' bgClip='text'
            title="Coming soon!"
            alt="stream.tube coming soon."
            _hover={{
              transition: '0.25s all ease-in-out',
              color: 'white'
            }}>stream.tube</Text>
            <Text fontSize="xs" style={{ color: 'white'}}>a web application by No Longer Inc.</Text>
        </Heading>
      </Container>
      <ReactPlayer url={backgroundSrc} muted={true} playing={true} loop={true} playsInline={true} controls={false} width='100%' height='100%'/>
    </ChakraProvider>
  );
}

export default App;
